import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import {title, container} from "assets/jss/material-kit-react.jsx";

import {Typography} from "@material-ui/core";

const workStyle = {
    container,
    section: {
        padding: "70px 0"
    },
    title: {
        ...title,
        marginBottom: "50px",
        marginTop: "30px",
        minHeight: "32px",
        textDecoration: "none",
        textAlign: "center"
    },
    description: {
        color: "#999",
        textAlign: "center"
    },
    textCenter: {
        textAlign: "center"
    },
    textArea: {
        marginRight: "15px",
        marginLeft: "15px"
    },
    listStyle: {
        margin: "0.75em 0",
        padding: "0 1em",
        listStyle: "none",
        display: "block",
        textAlign: "center"
    },
    listItem: {
        "&::before": {
            content: '""',
            borderColor: "transparent #111",
            borderStyle: "solid",
            borderWidth: "0 0 1em 1em",
            display: "block",
            height: 0,
            width: 0,
            left: "-2em",
            top: "1em",
            position: "relative",
        },
        padding: "0 2em",
        display: "inline-block",
    }
};

class ContactSection extends React.Component {
    render() {
        const {classes} = this.props;
        return (
            <div className={classes.container}>
                <div className={classes.section}>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={12}>
                            <h2 className={classes.title}>Contact us</h2>
                            <h3 className={classes.description}>
                                You can reach us at
                            </h3>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <h4 className={classes.description}>
                                Georgia
                            </h4>
                            <Typography className={classes.description} variant="body2">Nari Solutions LLC</Typography>
                            <Typography className={classes.description} variant="body1">Levan Mikeladze Street 19</Typography>
                            <Typography className={classes.description} variant="body1">Tbilisi, Georgia</Typography>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <h3 className={classes.description}>
                                Write to us at
                            </h3>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <Typography className={classes.description} variant="body2">General inquiries</Typography>
                            <Typography className={classes.description} variant="body1">info@nari.ge</Typography>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        );
    }
}

export default withStyles(workStyle)(ContactSection);
